import { Navigate, Route, Routes } from 'react-router-dom';
import RootLayout from './RootLayout';
import HomePage from '../pages/HomePage';
import WarrantySupportTicketsPage from '../pages/warrantysupport/WarrantySupportTicketsPage';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import WarrantySupportTicketPage from '../pages/warrantysupport/WarrantySupportTicketPage';
import SupportLineTicketsPage from '../pages/supportline/SupportLineTicketsPage';
import SupportLineTicketPage from '../pages/supportline/SupportLineTicketPage';
import SearchResultsPage from '../pages/SearchResultsPage';

const RootRouter = () => {
    return (
            <Routes>
                <Route path="/">
                    <Route path="public">
                        <Route path="login" element={<LoginPage/>}/>
                        <Route path="logout" element={<LogoutPage/>}/>
                        <Route index element={<Navigate to="login"/>}/>
                    </Route>
                    <Route path="private" element={<RootLayout/>}>
                        <Route index element={<HomePage/>}/>
                        <Route path="warrantysupport">
                            <Route index element={<WarrantySupportTicketsPage/>}/>
                            <Route path=":ticketId" element={<WarrantySupportTicketPage/>}/>
                        </Route>
                        <Route path="supportline">
                            <Route index element={<SupportLineTicketsPage/>}/>
                            <Route path=":ticketId" element={<SupportLineTicketPage/>}/>
                        </Route>
                        <Route path="search">
                            <Route index element={<SearchResultsPage/>}/>
                            <Route path=":query" element={<SearchResultsPage/>}/>
                        </Route>
                    </Route>
                    <Route index element={<Navigate to="private"/>}/>
                </Route>
            </Routes>
    );
};

export default RootRouter;
