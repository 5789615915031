import { CSVLink } from 'react-csv';
import { forwardRef } from 'react';
import { Box } from '@mui/material';

const ExportLink = forwardRef((props, ref) => {
    const data = Object.fromEntries(
            Object.entries(props?.ticket).map(([key, v]) => {
                return [key,
                    (typeof v === 'string')
                        ? v.replaceAll('"', '\'')
                        : v];
            }),
    )
    delete data?.related

    return <Box ref={ref} sx={{m: 2}}>
        <CSVLink
                data={[data || {}]}
                separator={';'}
                enclosingCharacter={`"`}
                {...props}>
            {props.children}
        </CSVLink>
    </Box>
});

export default ExportLink