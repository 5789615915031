import {
    Box,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import useAttachments from '../hook/useAttachments';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const MessageAttachments = ({ticket}) => {
    const {data: attachments, isLoading} = useAttachments(ticket.UID)

    if (isLoading) {
        return <LinearProgress/>
    }
    const AttachmentList = () => {
        return (
                <List dense disablePadding>
                    {attachments && attachments.map((item, index) => (
                            <AttachmentItem key={index} item={item}/>
                    ))
                    }
                </List>
        )
    }

    const AttachmentItem = ({item}) => {
        return (
                <ListItem disablePadding disableGutters>
                    <ListItemButton component="a" href={item?.url} disableGutters>
                        <ListItemIcon>
                            <FileDownloadIcon/>
                        </ListItemIcon>
                        <ListItemText primary={item?.fileName.split('/').pop()}/>
                    </ListItemButton>
                </ListItem>
        )
    }

    return <>
        <Box>
            {!attachments || attachments.length === 0
                    ? <Typography color="text.secondary" variant={'subtitle2'}>
                        No attachments
                    </Typography>
                    :
                    <AttachmentList/>
            }
        </Box>
    </>

}
export default MessageAttachments