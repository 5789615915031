import { Link, Outlet } from 'react-router-dom';
import {
    AppBar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo from '../asset/img/abb-logo.svg';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import * as React from 'react';
import { useCallback, useState } from 'react';

const drawerWidth = 240;

const LogoImage = styled('img')(() => ({
    display: 'block',
    width: '94px',
    height: '59px',
    border: 'none',
}));

const RootLayout = () => {
    const {t} = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = useCallback(() => {
        setDrawerOpen(prevDrawerOpen => !prevDrawerOpen);
    }, []);

    const drawer = (
            <div>
                <Toolbar sx={{marginBottom: 3}}>
                    <Link to="/private/">
                        <LogoImage src={logo} alt="Logo"/>
                    </Link>
                </Toolbar>
                <List sx={{marginRight: 3}}>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/private/">
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t('RootLayout.buttons.home.title')}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/private/warrantysupport">
                            <ListItemIcon>
                                <FormatListBulletedIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t('RootLayout.buttons.warrantysupport.title')}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/private/supportline">
                            <ListItemIcon>
                                <FormatListBulletedIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t('RootLayout.buttons.supportline.title')}/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </div>
    );

    return (
            <Box sx={{display: 'flex'}}>
                <AppBar
                        position="fixed"
                        color="default"
                        sx={{
                            width: {sm: `calc(100% - ${drawerWidth}px)`},
                            ml: {sm: `${drawerWidth}px`},
                        }}>
                    <Toolbar>
                        <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{marginRight: 2, display: {sm: 'none'}}}>
                            <MenuIcon/>
                        </IconButton>
                        <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button variant="text"
                                    color="inherit"
                                    href="/.auth/logout?post_logout_redirect_uri=/public/logout"
                                    endIcon={<LogoutIcon color="secondary"/>}>
                                {t('RootLayout.buttons.logout.title')}
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}>
                    <Drawer variant="temporary"
                            open={drawerOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            sx={{
                                display: {xs: 'block', sm: 'none'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                            }}>
                        {drawer}
                    </Drawer>
                    <Drawer variant="permanent"
                            sx={{
                                display: {xs: 'none', sm: 'block'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                            }}
                            open>
                        {drawer}
                    </Drawer>
                </Box>
                <Box component="main"
                     sx={{flexGrow: 1, p: {lg: 3}, width: {sm: `calc(100% - ${drawerWidth}px)`}}}>
                    <Toolbar/>
                    {/*render child route elements*/}
                    <Outlet/>
                </Box>
            </Box>

    );
};

export default RootLayout;
