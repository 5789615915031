import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import RelatedMessagesTable from './RelatedMessagesTable';
import MessageHeader from './MessageHeader';
import MessageAttachments from './MessageAttachments';
import SourceIcon from '@mui/icons-material/Source';
import MailIcon from '@mui/icons-material/Mail';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useTranslation } from 'react-i18next';

const MessageTabs = ({ticket}) => {
    const [value, setValue] = useState(0)
    const {t} = useTranslation();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const TabPanel = props => {
        const {children, value, index, ...other} = props;

        return (
                <div
                        role="tabpanel"
                        hidden={value !== index}
                        id={`simple-tabpanel-${index}`}
                        {...other}
                >
                    {value === index && (
                            <Box sx={{py: 3}}>
                                <Box>{children}</Box>
                            </Box>
                    )}
                </div>
        )
    }

    return (
            <>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value}
                              onChange={handleChange}
                              variant="fullWidth">
                            <Tab icon={<ListAltIcon/>}
                                 iconPosition="start"
                                 label={t(`Ticket.tabs.classification`)}
                                 id="tab-0"/>
                            <Tab icon={<MailIcon/>}
                                 iconPosition="start"
                                 label={t(`Ticket.tabs.related`)}
                                 id="tab-1"/>
                            <Tab icon={<SourceIcon/>}
                                 iconPosition="start"
                                 label={t(`Ticket.tabs.attachments`)}
                                 id="tab-2"/>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <MessageHeader ticket={ticket}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <RelatedMessagesTable related={ticket?.related || []}/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <MessageAttachments ticket={ticket}/>
                    </TabPanel>
                </Box>
            </>
    )
}
export default MessageTabs