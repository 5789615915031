import {
    Alert,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    LinearProgress,
    Link as MuiLink,
    Typography
} from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment/moment'
import useSupportLineTicket from '../../hook/useSupportLineTicket'
import ExportLink from '../../component/ExportLink';
import MessageBody from '../../component/MessageBody';
import MessageTabs from '../../component/MessageTabs';

const SupportLineTicketPage = () => {
    const {t} = useTranslation()
    const {ticketId} = useParams()
    const {data: ticket, isLoading} = useSupportLineTicket(ticketId)

    if (isLoading) {
        return <LinearProgress/>
    }

    if (!ticket || Object.keys(ticket).length === 0) {
        return <Alert severity="warning">{t('SupportLinePage.errors.notFound')}</Alert>
    }

    const BreadcrumbHeader = () => {
        return <>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <MuiLink underline="hover" color="inherit" component={Link}
                         to={`/private/supportline/`}>
                    SupportLine
                </MuiLink>
                <MuiLink
                        underline="hover"
                        color="text.primary"
                        component={Link}
                        to={`/private/supportline/${ticket?.UID}`}
                >
                    {ticket?.Subject}
                </MuiLink>
            </Breadcrumbs>

            <Typography variant="subtitle2" sx={{p: 2}}>
                {`Sent ${moment(new Date(ticket?.PostedDate)).format('YYYY-MM-DD HH:mm')} by ${ticket?.From}`}
            </Typography>
        </>
    }

    return <Box width="100%">
        <Box display="flex">
            <Box flexGrow={1}>
                <Card sx={{border: 'none', minWidth: 300,}} variant="outlined">
                    <CardHeader
                            component={BreadcrumbHeader}
                    />
                    <CardContent sx={{overflow: 'auto', mb: 3}}>
                        <MessageTabs ticket={ticket}/>
                        <MessageBody ticket={ticket}/>
                        <Button variant="contained"
                                component={ExportLink}
                                ticket={ticket}
                                filename={`${ticket.UID}.csv`}
                                endIcon={<FileDownloadIcon/>}>
                            Download as CSV
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    </Box>

}

export default SupportLineTicketPage
