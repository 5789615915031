import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LogoutPage = () => {
    const {t} = useTranslation();

    return (
            <Box sx={{padding: 3}}>
                <Typography variant="h4" my={2}>
                    {t('LogoutPage.title')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('LogoutPage.body')}
                </Typography>
                <Button variant="contained" color="primary" href="/.auth/login/aad?post_login_redirect_uri=/private/">
                    {t('LogoutPage.buttons.login.title')}
                </Button>
            </Box>
    );
};

export default LogoutPage;
