import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const properties = [
    'From',
    'To',
    'Cc',
    'Bcc',
    'Recipients',
    'SendTo',
    'ForwardedDate',
    'CaseNumber',
    'Handler',
    'Department',
    'Hardware',
    'Software',
    'SerialNo',
    'Status',
    'StatusOfCase',
    'Country',
    'Resolution',
]

const MessageHeader = ({ticket}) => {
    const {t} = useTranslation();

    const headers = properties
            .filter(e => ticket.hasOwnProperty(e))
            .map((prop, index) => {
                return {
                    key: prop,
                    value: ticket[prop],
                }
            })
    const HeaderItem = ({header}) => {
        return <>
            <Grid item
                  xs={2}
                  sm={4}
                  md={4}
                  color="text.secondary"
                  variant="body2"
                  zeroMinWidth>
                <Typography style={{fontWeight: 'bold'}}
                            variant="body2">
                    {t(`Ticket.headers.${header.key}`)}:
                </Typography>
                <Typography style={{overflowWrap: 'break-word'}}
                            variant="body2">
                    {header.value}
                </Typography>
            </Grid>
        </>
    }

    return (
            <Grid container
                  spacing={{xs: 1, md: 2}}
                  columns={{xs: 4, sm: 8, md: 12}}>
                {
                    headers.map((header, index) => {
                        return <HeaderItem key={index} header={header}/>

                    })
                }
            </Grid>
    )
}
export default MessageHeader