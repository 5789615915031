import { useTicketApi } from '../context/TicketApiContext'
import { useQuery } from '@tanstack/react-query'
import { useSnackBar } from '../context/SnackBarContext';
import { useTranslation } from 'react-i18next';

const useAttachments = (ticketId) => {
    const {ticketApi} = useTicketApi()
    const {showSnackBar} = useSnackBar()
    const {t} = useTranslation()

    const getAttachments = async () => {
        if (!ticketId) {
            return {}
        }
        const {data} = await ticketApi.get(`/attachments/${ticketId}`)
        return data
    }

    return useQuery(['attachments', ticketId], getAttachments, {
        onError: (err) => {
            showSnackBar(t('Api.errors.http', {err}), 'error');
        },
    });
}

export default useAttachments
