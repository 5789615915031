import {
    Alert,
    Box,
    Breadcrumbs,
    Button,
    CardContent,
    CardHeader,
    LinearProgress,
    Link as MuiLink,
    Typography
} from '@mui/material'
import Card from '@mui/material/Card';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next'
import useWarrantySupportTicket from '../../hook/useWarrantySupportTicket'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment/moment';
import MessageBody from '../../component/MessageBody';
import ExportLink from '../../component/ExportLink';
import MessageTabs from '../../component/MessageTabs';

const WarrantySupportTicketPage = () => {
    const {t} = useTranslation()
    const {ticketId} = useParams()
    const {data: ticket, isLoading} = useWarrantySupportTicket(ticketId)

    if (isLoading) {
        return <LinearProgress/>
    }

    if (!ticket || Object.keys(ticket).length === 0) {
        return <Alert severity="warning">{t('WarrantySupportPage.errors.notFound')}</Alert>
    }

    const BreadcrumbHeader = () => {
        return <>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <MuiLink underline="hover" color="inherit" component={Link}
                         to={`/private/warrantysupport/`}>
                    WarrantySupport
                </MuiLink>
                <MuiLink
                        underline="hover"
                        color="text.primary"
                        component={Link}
                        to={`/private/warrantysupport/${ticket?.UID}`}
                >
                    {ticket?.Subject}
                </MuiLink>
            </Breadcrumbs>

            <Typography variant="subtitle2" sx={{p: 2, fontWeight: 'medium'}}>
                Sent: {moment(new Date(ticket?.PostedDate)).format('YYYY-MM-DD HH:mm')} by {ticket?.From}
            </Typography>
        </>
    }

    return <Box width="100%">
        <Box display="flex">
            <Box flexGrow={1}>
                <Card sx={{border: 'none', minWidth: 300}} variant="outlined">
                    <CardHeader
                            component={BreadcrumbHeader}
                    />
                    <CardContent sx={{overflow: 'auto', mb: 3}}>
                        <MessageTabs ticket={ticket}/>
                        <MessageBody ticket={ticket}/>
                        <Button variant="contained"
                                component={ExportLink}
                                ticket={ticket}
                                filename={`${ticket.UID}.csv`}
                                endIcon={<FileDownloadIcon/>}>
                            Download as CSV
                        </Button>
                    </CardContent>
                </Card>

            </Box>
        </Box>
    </Box>
}

export default WarrantySupportTicketPage
