import * as React from 'react';
import { useState } from 'react';
import { Box, InputAdornment, TextField, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSearch from '../hook/useSearch';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import LoadingButton from '@mui/lab/LoadingButton';

const HomePage = () => {
    const {t} = useTranslation();
    const [query, setQuery] = useState('');
    const {isLoading, mutateAsync: searchTrigger} = useSearch();
    const navigate = useNavigate();

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            const queryOptions = {
                page: 0,
                pageSize: 10,
                filterModel: {
                    items: [],
                    linkOperator: 'and',
                    quickFilterValues: query?.split(/\s+/),
                    quickFilterLogicOperator: 'and',
                },
                sortModel: [
                    {field: 'PostedDate', sort: 'desc'}
                ]
            }


            const result = await searchTrigger({queryOptions});
            // results are now in cache, redirect to results page
            if (result) {
                navigate(`/private/search/${query}`);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
            <Box component="form"
                 onSubmit={onSubmit}
                 autoComplete="off"
                 m={1}
            >
                <TextField id="searchInput"
                           name="searchInput"
                           placeholder={t('HomePage.input.search.placeholder')}
                           inputProps={{
                               maxLength: 255,
                               minLength: 1
                           }}

                           value={query}
                           onChange={handleChange}
                           required
                           InputProps={{
                               endAdornment: (
                                       <InputAdornment position="end">
                                           <LoadingButton loading={isLoading}
                                                          endIcon={<SearchIcon/>}
                                                          variant="text"
                                                          color="primary"
                                                          type="submit">
                                           </LoadingButton>

                                       </InputAdornment>
                               ),
                           }}

                />
            </Box>
    );
};

export default HomePage;
