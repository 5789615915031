import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const {t} = useTranslation();

    return (
            <Box sx={{padding: 3}}>
                <Typography variant="h3" sx={{marginBottom: 6}}>
                    {t('LoginPage.title')}
                </Typography>
                <Button variant="contained" color="primary" href="/.auth/login/aad?post_login_redirect_uri=/private/">
                    {t('LoginPage.buttons.login.title')}
                </Button>
            </Box>
    );
};

export default LoginPage;
