import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js'

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        enableDebug: false
    },
})

export const loadAppInsights = () => {
    if (process.env.NODE_ENV === 'production') {
        appInsights.loadAppInsights()
    }
}

export const AppInsightsProvider = ({children}) => {
    return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
}
