import React from 'react';
import RootRouter from './route/RootRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TicketApiProvider } from './context/TicketApiContext';
import { AppInsightsProvider } from './context/appInsights';
import { SnackBarProvider } from './context/SnackBarContext';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5,
            retry: false,
        },
    },
});

function App() {
    return (
            <AppInsightsProvider>
                <SnackBarProvider>
                    <QueryClientProvider client={queryClient}>
                        <TicketApiProvider>
                            <React.Suspense fallback="loading">
                                <RootRouter/>
                            </React.Suspense>
                        </TicketApiProvider>
                    </QueryClientProvider>
                </SnackBarProvider>
            </AppInsightsProvider>
    )
}

export default App;
