import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTicketApi } from '../context/TicketApiContext'
import { useSnackBar } from '../context/SnackBarContext';
import { useTranslation } from 'react-i18next';

const useSearch = () => {
    const queryClient = useQueryClient();
    const {ticketApi} = useTicketApi()
    const {showSnackBar} = useSnackBar()
    const {t} = useTranslation()

    return useMutation(
        async (params) => {
            if (!params?.queryOptions) {
                return {}
            }

            const {data} = await ticketApi.post(`/search/`, params.queryOptions);
            return data;
        },
        {
            onError: (err) => {
                showSnackBar(t('Api.errors.http', {err}), 'error');
            },
            onSuccess: (data, variables) => {
                queryClient.setQueryData(['search', variables.queryOptions], data);
            },
        },
    );
};

export default useSearch;
