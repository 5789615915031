import { Alert, Snackbar } from '@mui/material'
import { createContext, useContext, useState } from 'react'

const SnackBarContext = createContext({
    /**
     * Show an alert with given message and severity
     * @param _text
     * @param _severity
     */
    showSnackBar: (_text, _severity) => undefined
})

/**
 * Provides MUI toast-like brief notifications/alerts
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const SnackBarProvider = ({children}) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('info')

    const showSnackBar = (text, color) => {
        setMessage(text)
        setSeverity(color)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSeverity('info')
    }

    return (
            <SnackBarContext.Provider value={{showSnackBar}}>
                <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
                {children}
            </SnackBarContext.Provider>
    )
}

const useSnackBar = () => {
    const context = useContext(SnackBarContext);
    if (!context) {
        throw new Error('useSnackBar must be used within an SnackBarProvider');
    }

    return context
}

export { SnackBarProvider, useSnackBar }