import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import ABBvoiceWoff2 from '../asset/fonts/ABBvoice_W_Rg.woff2';
import { enUS } from '@mui/x-data-grid';
import { enUS as coreEnUS } from '@mui/material/locale';

const theme = createTheme({
        typography: {
            fontFamily: 'ABBvoice, Roboto, Helvetica, Arial, sans-serif',
            button: {
                textTransform: 'none'
            }
        },
        palette: {
            primary: {
                main: '#FF000F',
            },
            secondary: {
                main: '#262626',
            },
            error: {
                main: red.A400,
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
            @font-face {
              font-family: 'ABBvoice';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('ABBvoice'), local('ABBvoice-Regular'), url(${ABBvoiceWoff2}) format('woff2');
            }
          `,
            },
        },
    },
    enUS, // x-data-grid translations
    coreEnUS, // core translations
);

export default theme;