import { useTicketApi } from '../context/TicketApiContext'
import { useQuery } from '@tanstack/react-query'
import { useSnackBar } from '../context/SnackBarContext';
import { useTranslation } from 'react-i18next';


const useSupportLineTicket = (ticketId) => {
    const {ticketApi} = useTicketApi()
    const {showSnackBar} = useSnackBar()
    const {t} = useTranslation()

    const getTicket = async () => {
        if (!ticketId) {
            return {}
        }
        const {data} = await ticketApi.get(`/supportline/${ticketId}`)
        return data
    }

    return useQuery(['supportline', ticketId], getTicket, {
        onError: (err) => {
            showSnackBar(t('Api.errors.http', {err}), 'error');
        },
    })
}

export default useSupportLineTicket
