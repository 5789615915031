import { Divider, Typography } from '@mui/material';

const MessageBody = ({ticket}) => {
    return (
            <>
                <Divider variant="fullWidth" sx={{my: 2}}/>
                <Typography variant="body2"
                            component="div"
                            boxShadow={4}
                            p={3}
                            dangerouslySetInnerHTML={{__html: ticket?.Body}}>
                </Typography>
            </>
    )
}
export default MessageBody