import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import i18n from '../i18n/config';

const columns = [
    {
        field: 'posteddate',
        headerName: i18n.t('Ticket.headers.Posted'),
        flex: 1,
        type: 'date',
        valueGetter: ({value}) => value && new Date(value),
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return moment(new Date(params.value)).format('YYYY-MM-DD HH:mm')
        },
    },
    {
        field: 'status',
        headerName: i18n.t('Ticket.headers.Status'),
        flex: 1,
    },
    {
        field: 'subject',
        headerName: i18n.t('Ticket.headers.Subject'),
        flex: 3,
    },
];


const RelatedMessagesTable = ({related}) => {
    const navigate = useNavigate();
    const handleRowClick = (params) => {
        const source = params.row?.Source;
        if (source === 'WarrantySupport') {
            navigate(`/private/warrantysupport/${params.row.uid}`)
        } else if (source === 'SupportLine') {
            navigate(`/private/supportline/${params.row.uid}`)
        }
    };

    return (
            <div style={{width: '100%'}}>
                <DataGrid sx={{border: 0}}
                          rows={related}
                          getRowId={(row) => row.uid}
                          columns={columns}
                          pageSize={5}
                          density="compact"
                          autoHeight
                          disableColumnSelector
                          rowsPerPageOptions={[5]}
                          onRowClick={handleRowClick}
                          localeText={{
                              noRowsLabel: 'No related messages',
                          }}
                          initialState={{
                              sorting: {
                                  sortModel: [{field: 'posteddate', sort: 'desc'}],
                              },
                          }}
                />
            </div>
    );
}
export default RelatedMessagesTable