import Axios from 'axios'
import { createContext, useContext, useEffect, useMemo } from 'react'

const ticketApi = Axios.create({
    baseURL: '/api',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
    },
})

const TicketApiContext = createContext(undefined)

export const TicketApiProvider = ({children}) => {
    const value = useMemo(() => {
        return {ticketApi}
    }, [])

    useEffect(() => {
        const requestInterceptor = ticketApi.interceptors.request.use(async request => {
            return request
        })

        const responseInterceptor = ticketApi.interceptors.response.use(
                response => response,
                async error => {
                    return Promise.reject(error);
                },
        );

        return () => {
            ticketApi.interceptors.request.eject(requestInterceptor)
            ticketApi.interceptors.response.eject(responseInterceptor)
        }
    }, [])

    return <TicketApiContext.Provider value={value}>{children}</TicketApiContext.Provider>
}

export const useTicketApi = () => {
    const context = useContext(TicketApiContext);
    if (!context) {
        throw new Error('useTicketApi must be used within an TicketApiProvider');
    }


    return useContext(TicketApiContext);
}
