import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DataGrid, getGridDateOperators, getGridStringOperators, GridToolbar } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import useSupportLineTickets from '../../hook/useSupportLineTickets'
import moment from 'moment/moment';
import i18n from '../../i18n/config';

const defaultSort = [{field: 'PostedDate', sort: 'desc'}]
const stringFilterOperators = getGridStringOperators().filter(({value}) =>
        ['equals', 'contains'].includes(value),
);
const dateFilterOperators = getGridDateOperators().filter(({value}) =>
        ['is'].includes(value),
);
const columns = [
    {
        headerName: 'Posted',
        field: 'PostedDate',
        flex: 0.8,
        type: 'date',
        valueGetter: ({value}) => value && new Date(value),
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return moment(new Date(params.value)).format('YYYY-MM-DD HH:mm')
        },
        filterOperators: dateFilterOperators
    },
    {
        headerName: i18n.t('Ticket.headers.From'),
        field: 'From',
        flex: 1,
        filterOperators: stringFilterOperators,
    },
    {
        headerName: i18n.t('Ticket.headers.SendTo'),
        field: 'SendTo',
        flex: 1,
        filterOperators: stringFilterOperators,
    },
    {
        headerName: i18n.t('Ticket.headers.CaseNumber'),
        field: 'CaseNumber',
        flex: 1,
        filterOperators: stringFilterOperators,
    },
    {
        headerName: i18n.t('Ticket.headers.Status'),
        field: 'Status',
        type: 'singleSelect',
        valueOptions: [
            'Closed',
            'Escalated',
            'Evaluation',
            'In Progress',
            'Info',
            'Open',
            'Solution'
        ]
    },
    {
        headerName: i18n.t('Ticket.headers.Subject'),
        field: 'Subject',
        flex: 3,
        filterOperators: stringFilterOperators,
    }
]

const SupportLineTicketsPage = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [sortModel, setSortModel] = useState(defaultSort)
    const [filterModel, setFilterModel] = useState({
        items: [],
        linkOperator: 'and'
    })
    const queryOptions = useMemo(
            () => ({
                page,
                pageSize,
                filterModel,
                sortModel
            }),
            [page, pageSize, filterModel, sortModel],
    )

    const {data, isLoading} = useSupportLineTickets(queryOptions)

    const onFilterChange = useCallback((filterModel) => {
        setFilterModel(filterModel);
    }, [setFilterModel])


    const handleSortModelChange = useCallback((sortModel) => {
        setSortModel(sortModel)
    }, [setSortModel])

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCountState` from being undefined during the loading
    const [rowCountState, setRowCountState] = useState(
            data?.total || 0,
    )

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
                data?.total !== undefined
                        ? data?.total
                        : prevRowCountState,
        );
    }, [data?.total, setRowCountState])

    const handleRowClick = (params, e) => {
        if (e?.ctrlKey || e?.ctrlKey || e?.shiftKey) {
            navigate(`/private/supportline/${params.row.UID}`)
        } else {
            window.open(`/private/supportline/${params.row.UID}`, '_blank')?.focus();
        }
    }

    return (
            <Box>
                <Typography variant="h3" gutterBottom>
                    {t('SupportLinePage.title')}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{height: 400}}>
                            <DataGrid
                                    getRowId={(row) => row.UID}
                                    rows={data?.content || []}
                                    rowCount={rowCountState}
                                    columns={columns}
                                    rowsPerPageOptions={[10]}
                                    pagination
                                    page={page}
                                    pageSize={pageSize}
                                    paginationMode="server"
                                    onPageChange={(newPage) => setPage(newPage)}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    filterMode="server"
                                    onFilterModelChange={onFilterChange}
                                    sortingMode="server"
                                    onSortModelChange={handleSortModelChange}
                                    initialState={{
                                        sorting: {
                                            sortModel: defaultSort,
                                        },
                                    }}
                                    loading={isLoading}
                                    disableColumnSelector
                                    disableDensitySelector
                                    experimentalFeatures={{newEditingApi: true}}
                                    autoHeight
                                    components={{Toolbar: GridToolbar}}
                                    onRowClick={handleRowClick}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {debounceMs: 500},
                                        },
                                    }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
    )
}

export default SupportLineTicketsPage
