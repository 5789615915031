import { useQuery } from '@tanstack/react-query'
import { useTicketApi } from '../context/TicketApiContext'
import { useSnackBar } from '../context/SnackBarContext';
import { useTranslation } from 'react-i18next';

const useWarrantySupportTickets = (queryOptions) => {
    const {ticketApi} = useTicketApi()
    const {showSnackBar} = useSnackBar()
    const {t} = useTranslation()
    const getTickets = async () => {
        const {data} = await ticketApi.post(`/warrantysupport`, queryOptions)
        return data
    }
    return useQuery(['warrantysupport', queryOptions], getTickets, {
        onError: (err) => {
            showSnackBar(t('Api.errors.http', {err}), 'error');
        },
    })
}

export default useWarrantySupportTickets
